input.checkbox {
  position: relative;
  margin-left: 90%;
  margin-top: -150px;
  background-color: white;
  width: 30px;
}

label.container-input {
  display: inline-block;
  position: absolute;
  margin-left: 90%;
  margin-top: -150px;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label.container-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  color: #f5f0f0;
  display: block;
}

label.container-input span.checkmark {
  position: absolute;
  top: -50px;
  left: 100px;
  height: 40px;
  width: 40px;
  margin-right: -20px;
  background-color: gray;
  border-color: gray;
  display: block;
  -webkit-clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container .checkmark:after {
  left: 5px;
  top: 5px;
  height: 30px;
  width: 30px;
  /* border: solid black; */
  border-radius: 80%;
  /* background-image: url("../Images/like2.png"); */
  background-color: yellow;
  /* border-width: 0 3px 3px 0;  */
  /* -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg); */
  /* transform: rotate(45deg); */
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.container input:checked ~ .checkmark:after {
  display: block;
}
